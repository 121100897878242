/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-16 16:05:32
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-16 16:08:02
 */
import TrendData from './trendData.vue'
export default TrendData
